var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return [(_vm.Permiso != 'Colaborador')?_c('sidebar-item',{attrs:{"link":{
        name: _vm.$t('sidebar.mainHome'),
        icon: 'tim-icons icon-chart-pie-36',
        path: '/main/home'
      }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
        name: 'Autoservicio',
        icon: 'fas fa-house-user',
        path: '/empleado'
      }}}),_vm._l((_vm.Opciones),function(Opcion){return _c('div',{key:Opcion.name},[(!Opcion.Externo)?_c('sidebar-item',{attrs:{"link":Opcion}}):_c('div',{on:{"click":function($event){return _vm.LinkExterno(Opcion.path)}}},[_c('sidebar-item',{attrs:{"link":{
            name: Opcion.name,
            icon: Opcion.icon,
            // path: {},
            path: ("#" + (Opcion.name)),
          }}})],1)],1)})]}}])}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('home-navbar',{attrs:{"FotoURL":_vm.FotoURL,"Nombre":_vm.Nombre,"SuscripcionesUsuario":_vm.SuscripcionesUsuario}}),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }