import Axios from 'axios';
//const isProd = process.env.NODE_ENV === "production";
/*
import Base from '@/api-services/base'

//const BaseURL = 'http://localhost:50598';
const USUARIOS = `${Base.URL}/usuarios`;
const SESIONES = `${Base.URL}/sesiones`;*/
var config = {
    headers: {
        'Content-type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Headers': 'content-type',
     //   'Access-Control-Allow-Origin':'http://localhost:8118',
     //   "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
     //   "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
    }
};

export default {

    header() {
        //console.debug(localStorage.sesion);
        var config = {
            headers: {
            'Authorization': localStorage.sesion,
            'ApiKey':'7a5bbc58-7137-4c97-a43a-04701b658418'
            }
        };
        return config;
    },
    headerMultipart(onUploadProgress) {
        //console.debug(localStorage.sesion);
        var config = {
            headers: {
                'Authorization': localStorage.sesion,
            'Content-Type': 'multipart/form-data',
            'ApiKey': '7a5bbc58-7137-4c97-a43a-04701b658418'
            }
        };
        if (onUploadProgress != undefined)
            config.onUploadProgress = onUploadProgress;
        return config;
    },
  /*  usuario_nuevo(data) {
        return Axios.post(USUARIOS, data);
    },
    sesion_nueva(data) {
        console.debug(SESIONES);
        return Axios.post(SESIONES, data, config);
    },

*/
    
};
