<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar :background-color="sidebarBackground" :short-title="$t('sidebar.shortTitle')" :title="$t('sidebar.title')">
      <template slot-scope="props" slot="links">
        <sidebar-item v-if="Permiso != 'Colaborador'" :link="{
          name: $t('sidebar.mainHome'),
          icon: 'tim-icons icon-chart-pie-36',
          path: '/main/home'
        }">
        </sidebar-item>
        <sidebar-item :link="{
          name: 'Autoservicio',
          icon: 'fas fa-house-user',
          path: '/empleado'
        }">
        </sidebar-item>
        <div v-for="Opcion in Opciones" :key="Opcion.name">
          <sidebar-item v-if="!Opcion.Externo"  :link="Opcion"  >
  
          </sidebar-item>
          
          <div v-else @click="LinkExterno(Opcion.path)">
            <sidebar-item :link="{
              name: Opcion.name,
              icon: Opcion.icon,
              // path: {},
              path: `#${Opcion.name}`,
            }">
            </sidebar-item>
          </div>
        </div>
      </template>

    </side-bar>

    <div class="main-panel" :data="sidebarBackground">
      <home-navbar :FotoURL="FotoURL" :Nombre="Nombre" :SuscripcionesUsuario="SuscripcionesUsuario"></home-navbar>
      <router-view name="header"></router-view>

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <!-- <div class="w-100 d-flex justify-content-center">
        <router-link :to="{}" @click.native.stop="Atras" tabindex="-1" class="p-2">
          <i class="fas fa-chevron-left"></i> Atras
        </router-link>
      </div> -->
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import Usuarios from '@/api-services/usuarios.service';
import { ZoomCenterTransition } from 'vue2-transitions';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {

    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import moment from "moment";
import HomeNavbar from './EmpleadoNavbar.vue';
import ContentFooter from './ContentFooter.vue';

import SuscripcionesUsuarios from '@/api-services/suscripciones.usuarios.service';
import Servicio from "@/api-services/colaboradores.service";
import Ubits from "@/api-services/ubits.service";
import Autoservicio from "@/api-services/autoRegistros2.service";
export default {
  components: {
    HomeNavbar,
    ContentFooter,
    ZoomCenterTransition
  },
  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
      error: '',
      trabajando: false,
      MostrarEmbajador: false,
      FotoURL: '/img/default-avatar.png',
      Nombre: '',
      SuscripcionesUsuario: [],
      Datos: null,
      Opciones: [],
      Permiso: null,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      console.debug(navigator.platform);
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    },
    Atras() {
      console.debug("Esto tiene", window.history.length)
      if (window.history.length > 2) this.$router.go(-1);
      else {
        this.$router.push("/autoservicio");
      }

    },
    CrearOpciones() {
      Servicio.getHome(
        this,
        "0",
        {
          desde: moment().subtract(7, "days").format(),
          hasta: moment().subtract(1, "days").format(),
        },
        (response) => {
          let R = response.data;
          // console.debug(R);
          this.Datos = R;
          R.autoServicioConfig.autoServicioOpciones.forEach((e) => {
            this.ValidarOpcion(e);
          });
          // Yo.Nombre = R.nombre;
          // if (this.$SuscripcionesUsuarios.esAdministrador()) {
          //   this.AgregaAutoServicioID("ConfiguracionGeneral", Container);
          // }
        },
        (msgError) => { }
      );
    },
    ValidarOpcion(Opcion) {
      if (Opcion.borrado) return;
      if (Opcion.restringir) {
        //Si no tiene permiso al dispositivo
        if (
          !this.Datos.dispositivosIDsAutoservicio.includes(
            Opcion.dispositivoID
          )
        )
          return;
      }
      let url = ""
      switch (Opcion.id) {
        case "Link":
        this.Opciones.push(  {
          name: Opcion.etiqueta,
          icon: Opcion.logo,
          path: Opcion.carga.URL,
          Externo: true
        })
          break;

        case "Ubits":
        Ubits.getLogin(this, (response) => {
        url = response.data;
        this.Opciones.push(  {
          name: Opcion.etiqueta,
          icon: Opcion.logo != null ? Opcion.logo : "" ,
          path: url,
          Externo: false
        })
      });
          break;
        case "AutoRegistros":
        Autoservicio.getLogin(this, (response) => {
        url = response.data;
        this.Opciones.push(  {
          name: Opcion.etiqueta,
          icon: Opcion.logo != null ? Opcion.logo : "fas fa-user-edit",
          path: url,
          Externo: true
        })
      });
          break;

        case "Checador":
        this.Opciones.push(  {
          name: Opcion.etiqueta,
          icon: "fas fa-fingerprint",
          path: "/empleado/acceso-nuevo",
          Externo: false
        })
          break;
        case "Incidencias":
        this.Opciones.push(  {
          name: Opcion.etiqueta,
          icon: "fas fa-car-crash",
          path: "/empleado/incidencias",
          Externo: false
        })
          break;
        case "Vacaciones":
        this.Opciones.push(  {
          name: Opcion.etiqueta,
          icon: "fas fa-umbrella-beach",
          path: "/empleado/vacaciones",
          Externo: false
        })
          break;
        case "Asistencias":
        this.Opciones.push(  {
          name: Opcion.etiqueta,
          icon: "fas fa-book",
          path: "/empleado/asistencias",
          Externo: false
        })
          break;
        case "Turnos":
        this.Opciones.push(  {
          name: Opcion.etiqueta,
          icon: "fas fa-clock",
          path: "/empleado/turnos",
          Externo: false
        })
          break;

        case "Comentarios":
        this.Opciones.push(  {
          name: Opcion.etiqueta,
          icon: "fas fa-comments",
          path: "/empleado/comentarios",
          Externo: false
        })
          break;

        default:
          break;
      }
    },
    LinkExterno(Link){
      window.open(Link, '_blank');
    },
  },
  mounted() {
    this.trabajando = true;
    Usuarios.getPerfilLocal((UsuarioPerfil) => {
      console.debug(UsuarioPerfil);

      ///https://github.com/Microsoft/ApplicationInsights-JS/issues/571
      //El usuario seleccioinado, no estoy seguro de la opción correcta
      this.$appInsights.context.user.id = `${UsuarioPerfil.usuario.usuarioID}`;
      this.$appInsights.context.user.userId = `${UsuarioPerfil.usuario.usuarioID}`;

      //this.$appInsights.context.user.user_AuthenticatedId = `${UsuarioPerfil.usuario.usuarioID}`;
      //La suscripción seleccionada
      this.$appInsights.context.user.accountId = localStorage.suscripcionID;
      // this.$appInsights.config.accountId = UsuarioPerfil.usuario.usuarioID;
      if (UsuarioPerfil.usuario.urlFotoMiniatura != null && UsuarioPerfil.usuario.urlFotoMiniatura != "") {
        this.FotoURL = UsuarioPerfil.usuario.urlFotoMiniatura;
      }

      var Componentes = UsuarioPerfil.usuario.nombre.split(" ");
      this.Nombre = Componentes[0];

      console.debug("HomeLayOut.Actualizado");
      this.SuscripcionesUsuario = UsuarioPerfil.usuario.suscripcionesUsuario;
      //console.debug(this.SuscripcionesUsuario);
      ///No tiene suscripción
      if (UsuarioPerfil.usuario.suscripcionesUsuario < 1) {
        var Ubicacion = window.location.href;
        //console.debug(Ubicacion);
        ///Si es un usuario nuevo
        /*  if (Ubicacion.indexOf("usuarios/nuevo") > 0)
            return;*/
        //var Pos = Ubicacion.indexOf("/");
        // var NUbicacion = Ubicacion.slice(0, Pos) +
        var NUbicacion = "/#/suscripciones/pregunta";
        //console.debug(NUbicacion);

        window.location.href = NUbicacion;
        // console.debug(Ubicacion.slice(0, Pos) + "/dashboard");
        //Ubicacion = Ubicacion.replace("index.html", "");
        return;
      }

      var Suscripcion = SuscripcionesUsuarios.getSuscripcion();
      let permisos =  UsuarioPerfil.usuario.suscripcionesUsuario.filter(Sus => Sus.suscripcionID == Suscripcion.suscripcionID)
      console.debug("Los permisos de la suscripcion son: ", permisos)
      this.Permiso = permisos[0].perfilID
      //   this.MostrarEmbajador = Suscripcion.esEmbajador;
      /*  for (const t of SuscripcionesUsuarios.Suscripciones) {
          switch (t.perfilID) {
            case 1:
              this.Usuarios = true;
              this.Tablas = true;
              this.Suscripciones = true;
              this.Proyectos = true;
              break;
            case 2:
              //  this.Usuarios = true;
              //  this.Reuniones = true;
              break;
            case 3:

              break;
            case 4:

              break;
          }
        }*/
      // console.debug(response.data);
      this.trabajando = false;

    });
    this.initScrollbar();
    console.debug(localStorage.Obscuro);
    if (localStorage.Obscuro != null) {
      let docClasses = document.body.classList;
      console.debug(docClasses);
      if (localStorage.Obscuro == "true") {
        console.debug(" docClasses.remove('white-content');");
        docClasses.remove('white-content');
      } else {
        console.debug("docClasses.add('white-content')");
        docClasses.add('white-content');
      }
    }
    this.CrearOpciones()
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }

}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
