<template>

    <div class="" :class="pageClass">
      <div class="content">
        <zoom-center-transition :duration="pageTransitionDuration"
                                mode="out-in">
          <router-view></router-view>
        </zoom-center-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>


</template>
<script>import { BaseNav } from 'src/components';
  import { ZoomCenterTransition } from 'vue2-transitions';
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import ContentFooter from './ContentFooter.vue';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    components: {
      BaseNav,
      ZoomCenterTransition,
      ContentFooter
    },
    props: {
      backgroundColor: {
        type: String,
        default: 'black'
      }
    },
    data() {
      return {
        tieneMenu: true,
        showMenu: false,
        menuTransitionDuration: 250,
        pageTransitionDuration: 200,
        year: new Date().getFullYear(),
        pageClass: 'login-page'
      };
    },
    computed: {
      title() {
        return `${this.$route.name}`;
      }
    },
    methods: {

      setPageClass() {
        this.pageClass = "";
      }
    },
    beforeDestroy() {
      this.closeMenu();
    },
    beforeRouteUpdate(to, from, next) {
      // Close the mobile menu first then transition to next page
      if (this.showMenu) {
        this.closeMenu();
        setTimeout(() => {
          next();
        }, this.menuTransitionDuration);
      } else {
        next();
      }
    },
    mounted() {
      this.setPageClass();
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
      //  initScrollbar(`full-page`);
        //initScrollbar(`content`);
        initScrollbar(`wrapper`);
      //  initScrollbar(`wrapper-full-page`);
        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }

    },
    watch: {
      $route() {

        this.setPageClass();
      }
    }
  };</script>
<style lang="scss">
 
</style>
