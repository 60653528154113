<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :url="url"
      :title="$t('sidebar.title')"
    >
      <template slot-scope="props" slot="links">
        <sidebar-item
          v-for="menu in Menu"
          :key="menu.name"
          :link="{
            name: menu.name,
            icon: menu.icon,
            path: menu.path,
          }"
        >
          <sidebar-item
            v-for="subMenu in menu.children"
            :key="subMenu.name"
            :link="{
              name: subMenu.name,
              path: subMenu.path,
            }"
          >
          </sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel" :data="sidebarBackground" :style="Estilo">
      <home-navbar
        v-if="!$route.meta.hideHomeNavBar"
        :FotoURL="FotoURL"
        :Nombre="Nombre"
        :SuscripcionesUsuario="SuscripcionesUsuario"
        :soporteURL="soporteURL"
      ></home-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view v-if="!Suspendido"></router-view>
          <base-alert v-else type="danger">
            <strong>Alerta</strong> Esta suscripción esta suspendida, conectata
            a atención a cliente.
          </base-alert>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import Usuarios from "@/api-services/usuarios.service";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

var mainPanel = null;

function initScrollbar(className) {
  if (hasElement(className)) {
    if (className == "main-panel") {
      if (mainPanel != null) mainPanel.destroy();
      mainPanel = new PerfectScrollbar(`.${className}`);
    } else new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import HomeNavbar from "./HomeNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

import SuscripcionesUsuarios from "@/api-services/suscripciones.usuarios.service";
import { ZoomCenterTransition } from "vue2-transitions";
import Menu from "./Menu";

export default {
  components: {
    HomeNavbar,
    ContentFooter,
    ZoomCenterTransition,
  },
  data() {
    return {
      //   sidebarBackground: "orange", //vue|blue|orange|green|red|primary
      error: "",
      trabajando: false,

      MostrarEmbajador: false,
      Suspendido: false,

      FotoURL: "/img/default-avatar.png",
      url: "https://www.eClock.com.mx",
      soporteURL: "https://support.eclock.com.mx/portal/es/kb/eclock",
      Nombre: "",
      SuscripcionesUsuario: [],
      Menu: [],
    };
  },
  watch: {
    $route(to, from) {
      /* console.debug(to);
         console.debug(from);
         */
      if (from == undefined) {
        // console.debug("No actualiza");
        return;
      }
      if (
        (from.meta.hideContent && from.meta.hideFooter) !=
        (to.meta.hideContent && to.meta.hideFooter)
      )
        this.ActualizaScrollMainPanel();
    },
  },
  computed: {
    sidebarBackground() {
      return this.$SuscripcionesUsuarios.getColor(null);
    },
    Estilo() {
      if (this.$route.meta.hideContent && this.$route.meta.hideFooter)
        return "overflow: hidden;";
      return "";
    },
  },
  methods: {
    ActualizaScrollMainPanel() {
      if (this.$route.meta.hideContent && this.$route.meta.hideFooter) {
        if (mainPanel != null) mainPanel.destroy();
        mainPanel = null;
      } else initScrollbar("main-panel");
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      console.debug(navigator.platform);
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        //  return;
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("sidebar");
        this.ActualizaScrollMainPanel();
        initScrollbar("sidebar-wrapper");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
  },
  mounted() {
    this.trabajando = true;
    Usuarios.getPerfilLocal((UsuarioPerfil) => {
      this.$PerfilLocal = UsuarioPerfil;
      //console.debug(UsuarioPerfil);

      this.url = `https://www.eClock.com.mx?&siq_name=${UsuarioPerfil.usuario.nombre}&siq_email=${UsuarioPerfil.usuario.correo}`;
      this.soporteURL = `https://support.eclock.com.mx/portal/es/kb/eclock?&siq_name=${UsuarioPerfil.usuario.nombre}&siq_email=${UsuarioPerfil.usuario.correo}`;
      //soporteURL: 'https://support.eclock.com.mx/portal/es/kb/eclock'
      ///https://github.com/Microsoft/ApplicationInsights-JS/issues/571
      //El usuario seleccioinado, no estoy seguro de la opción correcta
      this.$appInsights.context.user.id = `${UsuarioPerfil.usuario.usuarioID}`;
      this.$appInsights.context.user.userId = `${UsuarioPerfil.usuario.usuarioID}`;

      //this.$appInsights.context.user.user_AuthenticatedId = `${UsuarioPerfil.usuario.usuarioID}`;
      //La suscripción seleccionada
      this.$appInsights.context.user.accountId = localStorage.suscripcionID;
      // this.$appInsights.config.accountId = UsuarioPerfil.usuario.usuarioID;
      if (
        UsuarioPerfil.usuario.urlFotoMiniatura != null &&
        UsuarioPerfil.usuario.urlFotoMiniatura != ""
      ) {
        this.FotoURL = UsuarioPerfil.usuario.urlFotoMiniatura;
      }

      this.Nombre = UsuarioPerfil.usuario.nombre
        ? UsuarioPerfil.usuario.nombre.split(" ")[0]
        : "Usuario";

      console.debug("HomeLayOut.Actualizado");
      this.SuscripcionesUsuario = UsuarioPerfil.usuario.suscripcionesUsuario;
      //console.debug(this.SuscripcionesUsuario);
      ///No tiene suscripción
      if (UsuarioPerfil.usuario.suscripcionesUsuario.length < 1) {
        var Ubicacion = window.location.href;
        //console.debug(Ubicacion);
        ///Si es un usuario nuevo
        /*  if (Ubicacion.indexOf("usuarios/nuevo") > 0)
              return;*/
        //var Pos = Ubicacion.indexOf("/");
        // var NUbicacion = Ubicacion.slice(0, Pos) +
        var NUbicacion = "/#/suscripciones/pregunta";
        //console.debug(NUbicacion);

        window.location.href = NUbicacion;
        // console.debug(Ubicacion.slice(0, Pos) + "/dashboard");
        //Ubicacion = Ubicacion.replace("index.html", "");
        return;
      }

      var Suscripcion = SuscripcionesUsuarios.getSuscripcion();
      if (UsuarioPerfil.usuario.esSuperAdministrador) {
        this.MostrarEmbajador = true;
        this.Menu = Menu.ObtenMenu("SuperAdministrador", this.MostrarEmbajador);
      } else {
        var SU = SuscripcionesUsuarios.getSuscripcionUsuario();
        this.MostrarEmbajador = Suscripcion.esEmbajador;
        this.Menu = Menu.ObtenMenu(SU.perfilID, this.MostrarEmbajador);
      }
      // console.debug(response.data);
      let TipoPlan = SuscripcionesUsuarios.getSuscripcion();
      let Plan = TipoPlan.plan
      if (Plan == "Bronce") {
        this.Menu = this.Menu.filter(Opcion => Opcion.name != "Asistencias")
        this.Menu = this.Menu.filter(Opcion => Opcion.name != "Solicitudes")
        this.Menu = this.Menu.filter(Opcion => Opcion.name != "Evaluaciones")
        // let Asistencia = this.Menu.find(Opcion => Opcion.name =="Asistencias")
        // if (Asistencia) {
        //   Asistencia.children = Asistencia.children.filter(Opt => Opt.name !=="Listado")
        // }
        // let Evaluaciones = this.Menu.find(Opcion => Opcion.name =="Evaluaciones")
        // if (Evaluaciones) {
        //   Evaluaciones.children = Evaluaciones.children.filter(Opt => Opt.name !=="NOM-035-STPS-2018")
        // }
        let Personal = this.Menu.find(Opcion => Opcion.name =="Personal")
        if (Personal) {
          Personal.children = Personal.children.filter(Opt => Opt.name != "Centros de Costos")
          Personal.children = Personal.children.filter(Opt => Opt.name != "Gestiones")
        }
        let Checadas = this.Menu.find(Opcion => Opcion.name =="Checadas")
        if (Checadas) {
          Checadas.children = Checadas.children.filter(Opt => Opt.name != "Comedor")
        }
        let Ubicaciones = this.Menu.find(Opcion => Opcion.name =="Ubicaciones")
        if (Ubicaciones) {
          Ubicaciones.children = Ubicaciones.children.filter(Opt => Opt.name != "Rondines")
        }
      }

      else if(Plan == "Plata"){
        let Personal = this.Menu.find(Opcion => Opcion.name =="Personal")
        if (Personal) {
          Personal.children = Personal.children.filter(Opt => Opt.name != "Gestiones")
        }
        let Asistencia = this.Menu.find(Opcion => Opcion.name =="Asistencias")
        if (Asistencia) {
          Asistencia.children = Asistencia.children.filter(Opt => Opt.name !=="Reglas de Vacaciones")
          Asistencia.children = Asistencia.children.filter(Opt => Opt.name !=="Periodos de nóminas")
        }
         let Evaluaciones = this.Menu.find(Opcion => Opcion.name =="Evaluaciones")
        if (Evaluaciones) {
          Evaluaciones.children = Evaluaciones.children.filter(Opt => Opt.name !=="NOM-035-STPS-2018")
        }
      }
      this.Suspendido = Suscripcion.borrado;
      this.trabajando = false;
    });
    this.initScrollbar();
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
