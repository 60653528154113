import Base from "@/api-services/base";
const BaseURL = `AutoRegistros`;

export default {
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    let URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  /*
   * Obtiene listado completo de autoregistro acorde al filtro
   *
   *
   * @param {*} Vista Contiene el control que llama esta funcion
   * @param {*} BaseGetsListado contiene el filtro de datos
   * @param {*} OnCorrecto Se ejecuta cuando el estatus 2xx es correcto
   * @param {*} OnErroneo Se ejecuta cuando fue erroneo
   * @param {*} OnFinalizado cuando termino de ejecutar los anteriores (SIn importar el estatus anterior)
   * @returns
   */

  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      BaseURL,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },

  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    console.log("get", id);
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(
      Vista,
      BaseURL,
      Datos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  delete(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.deleteBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}`,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getLogin(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/Login`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  patch(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    let URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.patch(
      Vista,
      URL,
      Datos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  patchReferencias(Vista, OnCorrecto, OnError, OnFinalizado) {
    let URL = Base.ObtenURLSuscripcion(`${BaseURL}/Referencias`);
    return Base.patch(Vista, URL, {}, {}, OnCorrecto, OnError, OnFinalizado);
  },
  ObtenUrlLinkIDAutoregistro(LinkID) {
    return `https://ar.eClock.com.mx/r/${LinkID}`;
  },
  ExcelReporteGets(
    Vista,
    BaseGetsListado,
    OnCorrecto,
    OnErroneo,
    OnFinalizado
  ) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Excel/Reporte`);
    if (OnCorrecto == null) {
      OnCorrecto = (response) => {
        Base.Response2FileDownload(response);
      };
    }
    return Base.get(
      Vista,
      URL,
      { responseType: "blob", params: BaseGetsListado },
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getConfig(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    let URL = Base.ObtenURLSuscripcion(`${BaseURL}/Config`);
    return Base.get(Vista, URL, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
};
