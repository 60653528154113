import Base from "@/api-services/base";
const BaseURL = `Solicitudes`;

export default {
  GetsResponse: null,
  Listado: [],
  FotoURL(Registro) {
    return "/img/grupos.svg";
  },
  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      BaseURL,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  GetsColaboradorPrevio(
    Vista,
    BaseGetsListado,
    OnCorrecto,
    OnErroneo,
    OnFinalizado
  ) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/Colaboradores/Previo`,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  GetsEnriquecido(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/Enriquecido`,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  GetsTotales(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    console.debug("GetsTotales");
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/Totales`,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  GetsAgrupadas(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/Agrupadas`,
      BaseGetsListado,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  getsSelect(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    if (this.GetsResponse != null) OnCorrecto(this.GetsResponse);
    return Base.getsBaseSuscripcion(
      Vista,
      BaseURL,
      { PaginaLen: 1000 },
      (response) => {
        this.GetsResponse = response;
        this.Listado = response.data.listado;
        if (OnCorrecto != undefined) OnCorrecto(response);
      },
      OnErroneo,
      OnFinalizado
    );
  },
  /**
   * Agrega un comentario a una solicitud
   * @param {*} Vista 
   * @param {*} ID Identificador de la solicitud
   * @param {*} Datos {comentario: "El Comentario Comentario"}
   * @param {*} OnCorrecto 
   * @param {*} OnErroneo 
   * @param {*} OnFinalizado 
   * @returns 
   */
  postComentario(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}/Comentarios`);
    return Base.post(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(
      Vista,
      BaseURL,
      Datos,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}`,
      {},
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  delete(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.deleteBaseSuscripcion(
      Vista,
      `${BaseURL}/${id}`,
      OnCorrecto,
      OnErroneo,
      OnFinalizado
    );
  },
  EsPendiente(Solicitud) {
    return Solicitud.estadoSolicitud != "Autorizada" && Solicitud.estadoSolicitud != "Denegada";
  },
  GetsExcel(
    Vista,
    BaseGetsListado,
    OnErroneo,
    OnFinalizado
  ) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Excel`);

    return Base.get(
      Vista,
      URL,
      { responseType: "blob", params: BaseGetsListado },
      (response) => {
        Base.Response2FileDownload(response);
      },
      OnErroneo,
      OnFinalizado
    );
  },
};
